.wrapper {
  height: 40px;
  border-radius: 10px;
  font-size: 16px;
  line-height: 16px;
  font-family: 'PT Root UI Bold';
  padding: 0px 12px 0px 12px;
  transition: all 0.125s ease-in-out;
  box-shadow: none !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.icon {
  margin-right: 0.4rem;
}

.icon > svg {
  max-height: 14px;
}

@media (max-width: 992px) {
  .icon > svg {
    max-height: 17px;
  }
}

.button:hover {
  cursor: pointer;
}

.coolInfo {
  color: #79809c;
  background-color: #e9ebf3;
}

.coolInfo:hover {
  background-color: white;
  color: black;
}

.coolWallet {
  background-color: #4965f0;
  color: white;
  padding: 0px 12px 0px 12px;
}

.coolWallet:hover {
  filter: brightness(110%);
}

.warmInfo {
  color: #b6a9a7;
  background-color: #fdf9f9;
}

.warmInfo:hover {
  background-color: white;
  color: black;
}

.warmWallet {
  background-color: #d63c5e;
  color: white;
  padding: 0px 12px 0px 12px;
}

.warmWallet:hover {
  filter: brightness(110%);
}

.whiteInfo,
.whiteWallet {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: rgb(95, 95, 95);
}

.whiteInfo:hover,
.whiteWallet:hover {
  background-color: #e2e3e8;
  color: black;
}

.whiteActive {
  background-color: #f4f4f8;
  color: black;
}

.whiteActive .icon {
  color: #d63c5e;
}

@media (max-width: 992px) {
  .wrapper,
  .button {
    height: 48px;
    font-size: 18px;
  }
}

.wrapper h1 {
  font-family: 'Londrina Solid';
  font-size: 5rem;
}

.bannerSection {
}

@media (max-width: 992px) {
  .wrapper {
    padding: 2rem;
  }

  .wrapper h1 {
    font-size: 3rem;
  }
}

@media (min-width: 992px) {
  .wrapper h1 {
    font-size: 7rem;
  }
}

.wrapper {
  padding: 1rem 2rem;
  color: var(--brand-black);
  align-self: flex-start !important;
}
.informationRow {
  margin-bottom: 2rem;
}
.activityRow {
  margin-bottom: 1rem;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.5rem;
}

h4 {
  font-size: 1rem;
  font-weight: 400;
}

.modalHeader {
  border-bottom: none;
  justify-content: flex-start;
  align-items: center;
}
.modalTitleWrapper {
  color: var(--brand-black);
}
.modalTitleWrapper h1 {
  font-size: xx-large;
  margin-left: 2rem;
}
.modalHeader button {
  align-self: flex-start;
}
.modalHeaderNounImgWrapper {
  width: 150px;
  height: 150px;
}
.currentBidCol {
  border-right: 1px solid var(--brand-black);
}
.auctionTimerCol {
  padding-left: 3rem;
}

@media (max-width: 992px) {
  .wrapper {
    padding: 1rem;
  }
  .auctionTimerCol {
    padding-left: 15px;
  }
  .fomoNounsLink {
    text-align: center;
  }
}

/* Fix Firefox navigation arrow alignment issues */
@-moz-document url-prefix() {
  .colAlignCenter {
    display: flex;
    align-items: center;
  }
}
.fomoNounsLink {
  color: #8c8d92;
}

.fomoNounsLink a,
.fomoNounsLink:hover a .fomoNounsLink:active a {
  color: #8c8d92;
  padding-left: 0.5rem;
}

.fomoNounsLink:hover,
.fomoNounsLink:hover a {
  text-decoration: underline;
  color: #8c8d9275;
}
